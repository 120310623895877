import Vue from 'vue'
import i18n from 'user/Login/utils/i18n'
import router from './router'
import {globalMixin} from 'common/js/global-mixin'
import store from 'user/Login/store/store'
import Login from 'user/Login/components/Login'
// import '@ungap/custom-elements'
import vueCustomElement from 'vue-custom-element'

Vue.mixin(globalMixin)
Vue.use(vueCustomElement)
Login.store = store
Login.router = router
Login.i18n = i18n
Vue.customElement('login-component', Login)
